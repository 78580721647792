import Vue from 'vue';
import Component from 'vue-class-component';
import GalleryData from "../../data/gallery";
import GalleryItem from "../../data/galleryItem";

interface gallery {
    id: number,
    text: string,
    title: string,
    project: string,
    src: string,
}
interface galleryIten {
    id: number,
    gallery_id: number,
    text: string,
    project: string,
    src: string,
}

@Component
export default class Gallery extends Vue {

  public gallery: gallery[] = GalleryData;
  public galleryItem: galleryIten[] = GalleryItem;
  public event:any = null;
  public clicked = false;
  public dialog = false;
  public selectedId:number|null = null;
  public selectedCarousel:string[] = [];
  public selectedCarouselInformation = '';
  public selectedModel = 0;
  public hover = false;

  public zoomOpen(project: any) {

    this.selectedCarousel = [];
    this.selectedCarouselInformation = this.gallery[project.id].text;
    this.selectedId = project.id;
    this.selectedModel = 0;
    let i;
    for (i = 0; i < this.galleryItem.length; i++) {
      if (this.galleryItem[i].gallery_id === this.selectedId) {
        this.selectedCarousel.push(
          this.galleryItem[i].project +'/'+ this.galleryItem[i].src

        );
      }
    }
    this.dialog = true;
  }
}
