interface carouselImage {
    id: number,
    src: string,
}
const carouselImages: carouselImage[] = [
    {
        id: 0,
        src: '0K9A2858-HDR-bewerkt.jpg',
    },
    {
        id: 1,
        src: '0K9A2875-HDR.jpg',
    },
    {
        id: 3,
        src: '0K9A2898-HDR.jpg',
    },
    {
        id: 4,
        src: '0K9A2981-bewerkt-2.jpg',
    },
    {
        id: 5,
        src: '0K9A3058.jpg',
    },
    {
        id: 6,
        src: '0K9A3124-bewerkt.jpg',
    },
    {
        id: 7,
        src: '0K9A3290-bewerkt.jpg',
    },
    {
        id: 8,
        src: '0K9A3544-Verbeterd-NR.jpg',
    },
    {
        id: 9,
        src: '0K9A3656.jpg',
    },
    {
        id: 10,
        src: 'TEST.png',
    }
]

export default carouselImages