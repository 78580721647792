interface gallery {
    id: number,
    text: string,
    title: string,
    project: string,
    src: string,
}
const gallery: gallery[] = [
    {
        id: 0,
        text: 'Even iets anders dan al het zwart wat tegenwoordig enorm in is. Deze klant haat zwart, dus moesten we afwijken van de "trends" en dit was natuurlijk even een uitdaging. De ruimte is van zichzelf niet zo groot, maar toch van alle gemakken voorzien. Als tegen antwoord bij zwart denk je natuurlijk aan wit, alleen bij wit moet je uitkijken dat je geen kille en steriele ruimte krijgt. Om toch een warme sfeer te creëren hebben we er voor gekozen om een champagne kleur tapijt op de grond te leggen, tapijt is natuurlijk van zichzelf al een warmer materiaal. Tevens hebben we ook de muren voorzien van een champagne kleur. Hierbij hebben we een bijpassend behang uitgezocht van het merk Arte. Door de vloer en de muren te voorzien van warmere kleuren konden ze rest van de meubels toch wit worden en kreeg je geen kille ruimte. Zo zie je maar dat overal een antwoord op is, ook als je een hekel hebt aan de kleur zwart!',
        title: 'Project Ellise benedenverdieping',
        project: 'ellise_ontwerp',
        src: '1.1.jpg',
    },
    {
        id: 1,
        text: 'Wat te doen met een grote open ruimte met middenin een paal die erin moet blijven staan. De keuze viel om toch twee aparte ruimtes gaan te creëren, die wel bij elkaar horen en één geheel vormen. Door niet een standaard muur te gebruiken maar een diepere muur te gebruiken, konden we aan beide zijdes een eigen meubel gaan realiseren. Met aan de ene kant een op maat gemaakt tv meubel en aan de andere kant een in de muur verwerkte kastenwand van de keuken. Om toch dynamiek te krijgen tussen de twee ruimtes is er in de muur een doorkijkhaard verwerkt. Dit creëert natuurlijk eenheid en het geeft meteen een knus gevoel. Dit knusse gevoel word doorgetrokken doormiddel van een zwarte houtlookplaat. Omdat dit tevens in de woonkamer en keuken terug blijft komen hebben we van de tweeruimtes toch één geheel kunnen maken.',
        title: 'Project Beneden verdieping Harrie',
        project: 'harrie',
        src: '5.0.jpg',
    },
    {
        id: 2,
        text: 'Hier een kleine greep van de ontwerpen van het gehele woonhuis. Het woonkamergedeelte ligt verlaagd in de woning, in de woonkamer is de keuze gemaakt voor een houten visgraatvloer, door de natuurlijke look krijg je meteen een warme uitstraling. Om nog meer een knus gevoel te creëren, hebben we verschillende dingen toegevoegd. Denk hierbij aan de scheiding tussen de woonkamer en de keuken doormiddel van een elektrische doorkijk haard, warme natuurtinten op de muur en het plafond in een zwarte kleur geschilderd.In de keuken zijn de natuurlijke materialen doorgetrokken, hier is alleen voor een iets stoerdere uitstraling gekozen dit doormiddel van bijvoorbeeld een gietvloer en een grote robuuste tafel.De ideale ruimte om veel gasten te ontvangen!',
        title: 'Project Woonhuis Monique',
        project: 'keuken_monique',
        src: '1.2.jpg',
    },
    {
        id: 3,
        text: 'Bij de meeste huizen uit de jaren 60 is uitbouwen een must, dit omdat het van zichzelf niet zn grote woningen zijn, maar het lastige bij een uitbouw is dat je niet het gevoel mag hebben dat het niet bij het originele huis hoort en hierdoor verloren aanvoelt. Dit gevoel kun je maar door verschillende manieren doorbreken één hiervan is eenheid. Door verschillende kleuren en materialen terug te laten komen krijg je een gevoel van eenheid. Juist door iets vanuit de aanbouw door te trekken in de andere bestaande ruimte, denk hierbij aan bijvoorbeeld behang. Je ziet in dit ontwerp dat ondanks de ruimte gescheiden zijn je toch mee word genomen naar de "andere" ruimte, dit gevoel is gelukt doormiddel van een simpele oplossing, namelijk het behang door te laten lopen. Samenhangend met de doorkijkhaard in het midden die bekleed is met de akoestische panelen, krijg je ook meteen een warm en knusse uitstraling. Zo hebben we het juiste gevoel kunnen creëren en lijkt het alsof het huis altijd zo is geweest!',
        title: 'Project woonhuis Marleen',
        project: 'marleen_1.0',
        src: '1.0.jpg',
    },
    {
        id: 4,
        text: 'In de naam zie je het al, dit is een verbouwde schuur. De klant wilde een paar dingen gaan verwerken in de schuur, een fijne bar om veel mensen te ontvangen, een ruimte om te kunnen sporten en ergens een tv verwerkt in een wat knussere ruimte zodat je ook met minder mensen fijn kan genieten. Het was even een uitdaging maar we hebben alles kunnen verwerken in de ruimte. Elke wens heeft zijn eigen zone gekregen in de ruimte.',
        title: 'Project schuur Marloes',
        project: 'marloes',
        src: 'Marloes 1.0.jpg',
    },
    {
        id: 5,
        text: 'Dit huis is een paar jaar geleden gebouwd, maar het miste altijd een gevoel van samenhang en warmte. Te beginnen bij de keuken. Hier hebben we een paar dingen toegevoegd voor de sfeer en warmte. Te beginnen in de keuken met iets simpels zoals een warme kleur op de muur, door de kleur door te trekken op het plafond creëer je ook meteen een leuk effect. Daarnaast een behang toegevoegd en een akoestisch wandpaneel. Deze drie dingen geven van zichzelf al een warm en knus gevoel. Laat nu deze kleuren en wandpanelen terug komen verder in de ruimte. hierdoor is het probleem van de eenheid ook meteen getackeld. Onze werkwijze is "laat iets drie keer terug komen in de ruimte tussen en je creëert je eigen stijl en een gevoel van eenheid".',
        title: 'Project bestaande benedenverdieping Janneke',
        project: 'optie_janneke',
        src: '2.0.jpg',
    },
     {
        id: 6,
        text: 'Het team van Wijndivas opende een tweede winkel en kwamen bij mij om de stijl van hun eerste winkel door te zetten. De moeilijkheid zat hem in dat hun eerste winkel geen daglicht bevatte en deze ruimte juist extreem veel licht bevat. De mediterrane stijl bevat ook nog eens een hoop lichte/aardse kleuren. Om het licht probleem te tackelen heb ik er voor besloten om er een zwart plafond erin te laten monteren. samenhangend met de grote ramen te blokkeren met grote etalages meubels hebben we toch een knusse ruimte kunnen creëren. Om nog een extra eyecatcher  te creëren hebben we een tapwand gerealiseerd wat meteen dient als opslag ruimte, hier kun je als klant lekker je eigen wijntje tappen.',
        title: 'Project Eindresultaat Wijndivas',
        project: 'eindresultaat_wijndivas',
        src: '1.0.jpg',
    },
     {
        id: 7,
        text: 'Druk en een lekker kleurtje op de muur, dit was het uitgangspunt van dit project. De klant kwam aan met dat ze van veel kleur en poespas hield, maar toch ook een rustige basis wilde hebben. Om deze werelden te combineren heb ik bij de meubels voor rust en neutrale tinten gekozen en ben ik lekker losgegaan met de muren. Hierdoor is het gelukt om de werelden samen te voegen en heb ik weer een klant blij kunnen maken met een heerlijk huis.',
        title: 'Project Benedenverdieping Femke',
        project: 'benedenverdieping_femke',
        src: '1.0.jpg',
    }
]

export default gallery