interface galleryItem {
    id: number,
    gallery_id: number,
    text: string,
    project: string,
    src: string,
}
const galleryItems: galleryItem[] = [
    {
        id: 0,
        gallery_id: 0,
        text: '',
        project: 'ellise_ontwerp',
        src: '1.1.jpg',
    },
    {
        id: 1,
        gallery_id: 1,
        text: '',
        project: 'harrie',
        src: '5.0.jpg',
    },
    {
        id: 2,
        gallery_id: 2,
        text: '',
        project: 'keuken_monique',
        src: '1.2.jpg',
    },
    {
        id: 3,
        gallery_id: 3,
        text: '',
        project: 'marleen_1.0',
        src: '1.0.jpg',
    },
    {
        id: 4,
        gallery_id: 4,
        text: '',
        project: 'marloes',
        src: 'Marloes 1.0.jpg',
    },
    {
        id: 5,
        gallery_id: 5,
        text: '',
        project: 'optie_janneke',
        src: '2.0.jpg',
    },
    {
        id: 6,
        gallery_id: 0,
        text: '',
        project: 'ellise_ontwerp',
        src: '1.2.jpg',
    },
    {
        id: 7,
        gallery_id: 0,
        text: '',
        project: 'ellise_ontwerp',
        src: '1.3.jpg',
    },
    {
        id: 8,
        gallery_id: 0,
        text: '',
        project: 'ellise_ontwerp',
        src: '1.4.jpg',
    },
    {
        id: 9,
        gallery_id: 0,
        text: '',
        project: 'ellise_ontwerp',
        src: '1.5.jpg',
    },
    {
        id: 10,
        gallery_id: 0,
        text: '',
        project: 'ellise_ontwerp',
        src: '1.6.jpg',
    },
    {
        id: 11,
        gallery_id: 0,
        text: '',
        project: 'ellise_ontwerp',
        src: '1.7.jpg',
    },
    {
        id: 12,
        gallery_id: 0,
        text: '',
        project: 'ellise_ontwerp',
        src: '1.8.jpg',
    },
    {
        id: 13,
        gallery_id: 0,
        text: '',
        project: 'ellise_ontwerp',
        src: '1.9.jpg',
    },
    {
        id: 14,
        gallery_id: 0,
        text: '',
        project: 'ellise_ontwerp',
        src: '1.10.jpg',
    },
    {
        id: 15,
        gallery_id: 0,
        text: '',
        project: 'ellise_ontwerp',
        src: '1.11.jpg',
    },
    {
        id: 16,
        gallery_id: 1,
        text: '',
        project: 'harrie',
        src: '5.1.jpg',
    },
    {
        id: 17,
        gallery_id: 1,
        text: '',
        project: 'harrie',
        src: '5.2.jpg',
    },
    {
        id: 18,
        gallery_id: 1,
        text: '',
        project: 'harrie',
        src: '5.3.jpg',
    },
    {
        id: 19,
        gallery_id: 1,
        text: '',
        project: 'harrie',
        src: '5.4.jpg',
    },
    {
        id: 20,
        gallery_id: 1,
        text: '',
        project: 'harrie',
        src: '5.5.jpg',
    },
    {
        id: 21,
        gallery_id: 1,
        text: '',
        project: 'harrie',
        src: '5.6.jpg',
    },
    {
        id: 22,
        gallery_id: 1,
        text: '',
        project: 'harrie',
        src: '5.7.jpg',
    },
    {
        id: 23,
        gallery_id: 1,
        text: '',
        project: 'harrie',
        src: '5.8.jpg',
    },
    {
        id: 24,
        gallery_id: 1,
        text: '',
        project: 'harrie',
        src: '5.9.jpg',
    },
    {
        id: 25,
        gallery_id: 1,
        text: '',
        project: 'harrie',
        src: '5.10.jpg',
    },
    {
        id: 26,
        gallery_id: 1,
        text: '',
        project: 'harrie',
        src: '5.11.jpg',
    },
    {
        id: 27,
        gallery_id: 2,
        text: '',
        project: 'keuken_monique',
        src: '1.3.jpg',
    },
    {
        id: 28,
        gallery_id: 2,
        text: '',
        project: 'keuken_monique',
        src: '1.4.jpg',
    },
    {
        id: 29,
        gallery_id: 2,
        text: '',
        project: 'keuken_monique',
        src: '1.5.jpg',
    },
    {
        id: 30,
        gallery_id: 2,
        text: '',
        project: 'keuken_monique',
        src: '1.6.jpg',
    },
    {
        id: 31,
        gallery_id: 3,
        text: '',
        project: 'marleen_1.0',
        src: '1.1.jpg',
    },
    {
        id: 32,
        gallery_id: 3,
        text: '',
        project: 'marleen_1.0',
        src: '1.2.jpg',
    },
    {
        id: 33,
        gallery_id: 3,
        text: '',
        project: 'marleen_1.0',
        src: '1.3.jpg',
    },
    {
        id: 34,
        gallery_id: 3,
        text: '',
        project: 'marleen_1.0',
        src: '1.4.jpg',
    },
    {
        id: 35,
        gallery_id: 3,
        text: '',
        project: 'marleen_1.0',
        src: '1.5.jpg',
    },
    {
        id: 36,
        gallery_id: 3,
        text: '',
        project: 'marleen_1.0',
        src: '1.6.jpg',
    },
    {
        id: 37,
        gallery_id: 3,
        text: '',
        project: 'marleen_1.0',
        src: '1.7.jpg',
    },
    {
        id: 38,
        gallery_id: 3,
        text: '',
        project: 'marleen_1.0',
        src: '1.8.jpg',
    },
    {
        id: 39,
        gallery_id: 3,
        text: '',
        project: 'marleen_1.0',
        src: '1.9.jpg',
    },
    {
        id: 40,
        gallery_id: 3,
        text: '',
        project: 'marleen_1.0',
        src: '1.10.jpg',
    },
    {
        id: 41,
        gallery_id: 3,
        text: '',
        project: 'marleen_1.0',
        src: '1.11.jpg',
    },
    {
        id: 42,
        gallery_id: 3,
        text: '',
        project: 'marleen_1.0',
        src: '1.12.jpg',
    },
    {
        id: 43,
        gallery_id: 3,
        text: '',
        project: 'marleen_1.0',
        src: '1.13.jpg',
    },
    {
        id: 44,
        gallery_id: 4,
        text: '',
        project: 'marloes',
        src: 'Marloes 1.1.jpg',
    },
    {
        id: 45,
        gallery_id: 4,
        text: '',
        project: 'marloes',
        src: 'Marloes 1.2.jpg',
    },
    {
        id: 46,
        gallery_id: 4,
        text: '',
        project: 'marloes',
        src: 'Marloes 1.3.jpg',
    },
    {
        id: 47,
        gallery_id: 4,
        text: '',
        project: 'marloes',
        src: 'Marloes 1.4.jpg',
    },
    {
        id: 48,
        gallery_id: 4,
        text: '',
        project: 'marloes',
        src: 'Marloes 1.5.jpg',
    },
    {
        id: 49,
        gallery_id: 4,
        text: '',
        project: 'marloes',
        src: 'Marloes 1.6.jpg',
    },
    {
        id: 50,
        gallery_id: 4,
        text: '',
        project: 'marloes',
        src: 'Marloes 1.7.jpg',
    },
    {
        id: 51,
        gallery_id: 4,
        text: '',
        project: 'marloes',
        src: 'Marloes 1.8.jpg',
    },
    {
        id: 52,
        gallery_id: 4,
        text: '',
        project: 'marloes',
        src: 'Marloes 1.9.jpg',
    },
    {
        id: 53,
        gallery_id: 4,
        text: '',
        project: 'marloes',
        src: 'Marloes 1.10.jpg',
    },
    {
        id: 54,
        gallery_id: 4,
        text: '',
        project: 'marloes',
        src: 'Marloes 1.11.jpg',
    },
    {
        id: 54,
        gallery_id: 4,
        text: '',
        project: 'marloes',
        src: 'Marloes 1.12.jpg',
    },
    {
        id: 55,
        gallery_id: 5,
        text: '',
        project: 'optie_janneke',
        src: '2.1.jpg',
    },
    {
        id: 56,
        gallery_id: 5,
        text: '',
        project: 'optie_janneke',
        src: '2.17.jpg',
    },
    {
        id: 57,
        gallery_id: 5,
        text: '',
        project: 'optie_janneke',
        src: '2.3.jpg',
    },
    {
        id: 58,
        gallery_id: 5,
        text: '',
        project: 'optie_janneke',
        src: '2.4.jpg',
    },
    {
        id: 59,
        gallery_id: 5,
        text: '',
        project: 'optie_janneke',
        src: '2.5.jpg',
    },
    {
        id: 60,
        gallery_id: 5,
        text: '',
        project: 'optie_janneke',
        src: '2.6.jpg',
    },
    {
        id: 61,
        gallery_id: 5,
        text: '',
        project: 'optie_janneke',
        src: '2.7.jpg',
    },
    {
        id: 62,
        gallery_id: 5,
        text: '',
        project: 'optie_janneke',
        src: '2.8.jpg',
    },
    {
        id: 63,
        gallery_id: 5,
        text: '',
        project: 'optie_janneke',
        src: '2.9.jpg',
    },
    {
        id: 64,
        gallery_id: 5,
        text: '',
        project: 'optie_janneke',
        src: '2.10.jpg',
    },
    {
        id: 65,
        gallery_id: 5,
        text: '',
        project: 'optie_janneke',
        src: '2.11.jpg',
    },
    {
        id: 66,
        gallery_id: 5,
        text: '',
        project: 'optie_janneke',
        src: '2.12.jpg',
    },
    {
        id: 67,
        gallery_id: 5,
        text: '',
        project: 'optie_janneke',
        src: '2.13.jpg',
    },
    {
        id: 68,
        gallery_id: 5,
        text: '',
        project: 'optie_janneke',
        src: '2.14.jpg',
    },
    {
        id: 69,
        gallery_id: 5,
        text: '',
        project: 'optie_janneke',
        src: '2.15.jpg',
    },
    {
        id: 70,
        gallery_id: 6,
        text: '',
        project: 'eindresultaat_wijndivas',
        src: '1.0.jpg',
    },
    {
        id: 71,
        gallery_id: 6,
        text: '',
        project: 'eindresultaat_wijndivas',
        src: '2.0.jpg',
    },
    {
        id: 72,
        gallery_id: 6,
        text: '',
        project: 'eindresultaat_wijndivas',
        src: '3.0.jpg',
    },
    {
        id: 73,
        gallery_id: 6,
        text: '',
        project: 'eindresultaat_wijndivas',
        src: '4.0.jpg',
    },
    {
        id: 74,
        gallery_id: 6,
        text: '',
        project: 'eindresultaat_wijndivas',
        src: '5.0.jpg',
    },
    {
        id: 75,
        gallery_id: 6,
        text: '',
        project: 'eindresultaat_wijndivas',
        src: '6.0.jpg',
    },
    {
        id: 76,
        gallery_id: 6,
        text: '',
        project: 'eindresultaat_wijndivas',
        src: '7.0.jpg',
    },
    {
        id: 77,
        gallery_id: 6,
        text: '',
        project: 'eindresultaat_wijndivas',
        src: '8.0.jpg',
    },
    {
        id: 78,
        gallery_id: 6,
        text: '',
        project: 'eindresultaat_wijndivas',
        src: '9.0.jpg',
    },
    {
        id: 79,
        gallery_id: 6,
        text: '',
        project: 'eindresultaat_wijndivas',
        src: '10.0.jpg',
    },
    {
        id: 80,
        gallery_id: 7,
        text: '',
        project: 'benedenverdieping_femke',
        src: '1.0.jpg',
    },
    {
        id: 81,
        gallery_id: 7,
        text: '',
        project: 'benedenverdieping_femke',
        src: '1.1.jpg',
    },
    {
        id: 82,
        gallery_id: 7,
        text: '',
        project: 'benedenverdieping_femke',
        src: '1.2.jpg',
    },
    {
        id: 83,
        gallery_id: 7,
        text: '',
        project: 'benedenverdieping_femke',
        src: '1.3.jpg',
    },
    {
        id: 84,
        gallery_id: 7,
        text: '',
        project: 'benedenverdieping_femke',
        src: '1.4.jpg',
    },
    {
        id: 85,
        gallery_id: 7,
        text: '',
        project: 'benedenverdieping_femke',
        src: '1.5.jpg',
    },
    {
        id: 86,
        gallery_id: 7,
        text: '',
        project: 'benedenverdieping_femke',
        src: '1.6.jpg',
    },
    {
        id: 87,
        gallery_id: 7,
        text: '',
        project: 'benedenverdieping_femke',
        src: '1.7.jpg',
    },
]

export default galleryItems