import Vue from 'vue';
import Component from 'vue-class-component';
import Gallery from "../../components/gallery/Gallery.vue";
import CarouselImages from "../../data/carouselImages";
interface carouselImage {
    id: number;
    src: string;
}
interface supplier {
    id: number,
    title: string,
    project: string,
    src: string,
}

@Component({
    components:{
        Gallery
    }
})
export default class Home extends Vue {
    public carouselImages:carouselImage[] = [];
    public hover = false;
    created() {
        this.carouselImages = CarouselImages
    }
    public OpenWebsite(text:string) {
        window.open(text,'_blank');
    }
}