import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#ffffff',
            secondary: '#25242e',
            accent: '#F5F5F5',
            error: '#9e9a9a',
          },
        },
      },
});
