import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class App extends Vue {
    public get phoneIcon(){
        let responsiveIcon: boolean;
        if(window.innerWidth <= 2560) {
            responsiveIcon = true;
        } else {
            responsiveIcon = false
        }
        return responsiveIcon;
    }
    public goTo(id:string) {
        const element = window.document.getElementById(id);
        if (element !== null) {
            console.log(element)
            element.scrollIntoView({behavior: "smooth"});
        } else {
            console.log(element)
        }
    }
}